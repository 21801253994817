import React, { FC } from "react"
import BrainImg from "../assets/images/brain.svg"
import { FormattedMessage } from "gatsby-plugin-intl"
import styled from "styled-components"
import media from "styled-media-query"
import { clamp } from "../styles/helpers"
import FullWidth from "./layout/full-width"
import { LimitedWidth } from "./layout/limited-width"
import { darken } from "polished"

type Props = {
  leftHeading?: string
  leftContent: string | JSX.Element
  rightHeading?: string
  rightContent: string | JSX.Element
}

const Brain: FC<Props> = ({
  leftHeading,
  leftContent,
  rightHeading,
  rightContent,
}) => (
  <FullWidth>
    <BrainContainer>
      <LimitedWidth>
        <LeftDescription>
          <strong>
            {leftHeading || <FormattedMessage id="brain.generic.leftHeading" />}
          </strong>
          <div>{leftContent}</div>
        </LeftDescription>

        <ImageContainer>
          <BrainImg />
        </ImageContainer>

        <RightDescription>
          <strong>
            {rightHeading || (
              <FormattedMessage id="brain.generic.rightHeading" />
            )}
          </strong>
          <div>{rightContent}</div>
        </RightDescription>
      </LimitedWidth>
    </BrainContainer>
  </FullWidth>
)

const BrainContainer = styled.div`
  position: relative;
  color: ${({ theme }) => theme.semanticColor.secondary};
  padding: ${clamp(1, 4)} 0;
  background: ${({ theme }) => theme.semanticColor.primary};
  background: radial-gradient(
    circle,
    ${({ theme }) => darken(0.1, theme.color.royalblue)},
    ${({ theme }) => theme.color.darkblue}
  );
  display: flex;
  ${media.lessThan("medium")`
   flex-direction: column;
  `}
  background:
`

const Description = styled.div`
  padding: 1rem;
  font-size: ${clamp(1, 1.2)};
  line-height: 1.3;
`

const LeftDescription = styled(Description)``

const RightDescription = styled(Description)`
  text-align: right;
`

const ImageContainer = styled.div`
  max-width: 170px;
  width: 50%;
  margin: 0 auto;
`

export default Brain
