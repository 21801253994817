import styled from "styled-components"
import React, { FC } from "react"
import media from "styled-media-query"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Tag } from "./tag"
import { GatsbyImage } from "gatsby-plugin-image"
import { LimitedWidth } from "./layout/limited-width"
import { clamp } from "../styles/helpers"

const ReferenceContainer = styled.section`
  max-width: ${({ theme }) => theme.size.width_limited_s}px;
  margin: 4rem auto 0;

  ${media.lessThan("medium")`
    margin-top: 2rem;
  `}
`
const LogoContainer = styled.div`
  width: 100px;
  margin: 0 auto;
`
const WideLogoContainer = styled.div`
  width: 200px;
  margin: 0 auto;
`

const ReferenceTitle = styled.h1``

const ReferenceMeta = styled.div`
  display: grid;

  margin-bottom: 2rem;
  align-items: flex-start;
`

const ReferenceContent = styled.div`
  font-size: 1.1rem;
  line-height: 1.5;
`

type Props = {
  id: string
  logo?: any
  wideLogo?: boolean
}

const Reference: FC<Props> = ({ id, logo, wideLogo = false, children }) => {
  const { formatMessage } = useIntl()

  const title = formatMessage({ id: `reference.${id}.title` })
  const useCase = formatMessage({ id: `reference.${id}.use-case` })
  const scope = formatMessage({ id: `reference.${id}.scope` })
  const industry = formatMessage({ id: `reference.${id}.industry` })

  return (
    <ContentWrapper>
      <ReferenceContainer>
        {logo &&
          (wideLogo ? (
            <WideLogoContainer>
              <GatsbyImage image={logo} alt="" />
            </WideLogoContainer>
          ) : (
            <LogoContainer>
              <GatsbyImage image={logo} alt="" />
            </LogoContainer>
          ))}

        <ReferenceTitle>{title}</ReferenceTitle>
        <ReferenceMeta>
          <Tag>
            <FormattedMessage id="reference.industry" />: {industry}
          </Tag>
          <Tag>Use-Case: {useCase}</Tag>
          <Tag>Scope: {scope}</Tag>
        </ReferenceMeta>
        <ReferenceContent>{children}</ReferenceContent>
      </ReferenceContainer>
    </ContentWrapper>
  )
}

export default Reference

export const InitialSituationHeading: FC = () => {
  const Heading = styled.h2``

  return (
    <Heading>
      <FormattedMessage id="reference.initial-situation" />
    </Heading>
  )
}

export const SolutionApproachHeading: FC = () => {
  const Heading = styled.h2``

  return (
    <Heading>
      <FormattedMessage id="reference.solution-approach" />
    </Heading>
  )
}

const ContentWrapper = styled(LimitedWidth)`
  padding-bottom: ${clamp(4, 8)};
`
