import React, { FC } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { clamp } from "../styles/helpers"
import { boxShadowSoft } from "../styles/theme"
import media from "styled-media-query"

const TestimonialContainer = styled.div`
  padding: ${clamp(2, 4)} 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;

  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
    justify-items: center;
  `}
`

const Statement = styled.blockquote`
  font-size: ${clamp(1.2, 1.5)};
  font-weight: bold;
  line-height: 1.3;
  margin: 0 0 1rem 0;
  display: flex;

  color: ${({ theme }) => theme.semanticColor.primary};
  opacity: 0.7;
  position: relative;
  padding: 0 1em;

  &:before,
  &:after {
    font-size: ${clamp(1.5, 3)};
    position: absolute;
  }

  &:before {
    top: -1rem;
    left: 0;
    content: "“";
  }

  &:after {
    right: 0;
    bottom: -2rem;
    content: "”";
  }
`

const From = styled.div`
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
  text-align: right;
`

const StyledImage = styled(GatsbyImage)`
  width: 170px;
  height: 170px;
  display: grid;
  border-radius: 5px;
  ${boxShadowSoft};
  overflow: hidden;
  filter: grayscale();
`

type Props = {
  statement: string
  from: string
  image?: any
}

const Testimonial: FC<Props> = ({ statement, from, image }) => (
  <TestimonialContainer>
    {image && <StyledImage image={image} alt="" />}
    <div>
      <Statement>{statement}</Statement>
      <From>&mdash; {from}</From>
    </div>
  </TestimonialContainer>
)

export default Testimonial
