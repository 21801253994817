import Page from "../../components/page"
import SEO from "../../components/seo"
import React from "react"
import Reference, {
  InitialSituationHeading,
  SolutionApproachHeading,
} from "../../components/reference"
import CustomFormattedMessage from "../../components/custom-formatted-message"
import Brain from "../../components/brain"
import Testimonial from "../../components/testimonial"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

const DataDrivenExperimentsPage = ({ location }: { location: Location }) => {
  const { formatMessage } = useIntl()

  const id = "data-driven-experimentation"
  const {
    homeScreenImg,
    rioLogo,
    testimonialImg,
    dataDrivenExperimentationImg,
  } = useStaticQuery(
    graphql`
      query {
        rioLogo: file(relativePath: { eq: "logos/rio-logo.png" }) {
          ...DefaultImageOptions
        }
        testimonialImg: file(
          relativePath: { eq: "references/rio_testimonial.jpg" }
        ) {
          ...DefaultImageOptions
        }
        homeScreenImg: file(
          relativePath: { eq: "references/rio-home-screen.png" }
        ) {
          ...DefaultImageOptions
        }
        dataDrivenExperimentationImg: file(
          relativePath: { eq: "references/data-driven-experimentation.png" }
        ) {
          ...DefaultImageOptions
        }
      }
    `
  )

  return (
    <Page>
      <SEO
        title={formatMessage({ id: `reference.${id}.title` })}
        description={formatMessage({ id: `reference.${id}.seoDescription` })}
        ogImage={getSrc(homeScreenImg)}
        location={location}
        canonicalUrl={formatMessage({ id: `reference.${id}.canonicalUrl` })}
      />
      <Reference id={id} logo={getImage(rioLogo)}>
        <InitialSituationHeading />
        <p>
          <CustomFormattedMessage id={`reference.${id}.initial-situation`} />
        </p>
        <SolutionApproachHeading />
        <Brain
          leftContent={
            <CustomFormattedMessage id={`reference.${id}.brain.left-content`} />
          }
          rightContent={
            <CustomFormattedMessage
              id={`reference.${id}.brain.right-content`}
            />
          }
        />
        <p>
          <CustomFormattedMessage id={`reference.${id}.solution-approach.p1`} />
        </p>
        <GatsbyImage
          className="full-bleed"
          image={getImage(homeScreenImg)!}
          alt=""
        />
        <p>
          <CustomFormattedMessage id={`reference.${id}.solution-approach.p2`} />
        </p>
        <GatsbyImage
          className="full-bleed"
          image={getImage(dataDrivenExperimentationImg)!}
          alt=""
        />
        <p>
          <CustomFormattedMessage id={`reference.${id}.solution-approach.p3`} />
        </p>
        <Testimonial
          statement={formatMessage({
            id: `reference.${id}.testimonial.statement`,
          })}
          from={formatMessage({ id: `reference.${id}.testimonial.from` })}
          image={getImage(testimonialImg)}
        />
        <p>
          <CustomFormattedMessage id={`reference.${id}.solution-approach.p4`} />
        </p>
      </Reference>
    </Page>
  )
}

export default DataDrivenExperimentsPage
