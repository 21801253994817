import React, { FC } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

// simple wrapper for <FormattedMessage /> from react-intl which defines some replacement rules
// for HTML-tags in the JSON-strings

const CustomFormattedMessage: FC<{ id: string }> = ({ id }) => (
  <FormattedMessage
    id={id}
    values={{
      em: (chunks: any) => <em>{chunks}</em>,
      strong: (chunks: any) => <strong>{chunks}</strong>,
    }}
  />
)

export default CustomFormattedMessage
