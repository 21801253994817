import styled from "styled-components"

export const Tag = styled.div`
  background: ${({ theme }) => theme.semanticColor.primary};
  color: ${({ theme }) => theme.semanticColor.body_text_light};
  font-weight: bold;
  border-radius: 3px;
  justify-self: left;
  padding: 0.35em 0.5em;
  margin-bottom: 0.5em;
  position: relative;
  left: -0.2em;
  font-size: 0.9rem;
  display: inline-block;
`
